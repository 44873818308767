<template>
  <div class="form-group">

    <label v-if="label" class="form-label">{{label}}</label>

    <div class="input-wrap">

      <span class="border-btn bg-lighter border-right-0 p-0" :class="btnStyle" @click="subtract"><i class="i-minus"></i></span>

      <input class="form-input rounded-0 border-left-0 border-right-0 text-center z-1" type="text"
             :class="style"
             :placeholder="placeholder"
             @change="check"
             @click="$emit('click', $event)"
             @keyup="$emit('keyup', $event)"
             @keydown="$emit('keydown', $event)"
             @keypress="$emit('keypress', $event)"
             @blur="$emit('blur', $event)"
             @focus="$emit('focus', $event)"
             v-model="_value">

      <span class="border-btn bg-lighter p-0 border-left-0" :class="btnStyle" @click="add"><i class="i-plus"></i></span>
    </div>

    <span v-if="help !== undefined" class="form-help">{{help}}</span>
  </div>
</template>

<script>
  export default {
    props: {
      placeholder: [String, Number],
      label: [String, Number],
      help: {
        type: [String, Number],
        default: undefined
      },
      min: Number,
      max: Number,
      step: Number,
      value: Number,
      valid: {
        type: Boolean,
        default: true
      },
      warning: {
        type: Boolean,
        default: false
      },
      success: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      _value: {
        get () { return this.value },
        set (value) {
          value = parseInt(value);
          this.$emit('input', parseInt(value));
        }
      },
      style() {
        return {
          'form-success': this.success,
          'form-warning': this.warning,
          'form-danger': !this.valid,
          'form-primary': this.valid
        }
      },
      btnStyle() {
        return {
          'border-btn-success': this.success,
          'border-btn-warning': this.warning,
          'border-btn-danger': !this.valid,
          'border-btn-primary': this.valid
        }
      }
    },
    methods: {

      subtract() {
        let res = this.step === undefined ? this._value - 1 : this._value - this.step;

        if(this.min === undefined || res >= this.min)
          this._value = res;

        this.$emit('subtract', this._value);
      },

      add() {
        let res = this.step === undefined ? this._value + 1 : this._value + this.step;

        if(this.max === undefined || res <= this.max)
          this._value = res;

        this.$emit('add', this._value);
      },

      check() {
        if(this.max !== undefined && this._value > this.max)
          this._value = this.max;

        if(this.min !== undefined && this._value < this.min)
          this._value = this.min;
      }
    }
  }
</script>
