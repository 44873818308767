var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group" }, [
    _vm.label
      ? _c("label", { staticClass: "form-label" }, [_vm._v(_vm._s(_vm.label))])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "input-wrap" }, [
      _c(
        "span",
        {
          staticClass: "border-btn bg-lighter border-right-0 p-0",
          class: _vm.btnStyle,
          on: { click: _vm.subtract }
        },
        [_c("i", { staticClass: "i-minus" })]
      ),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm._value,
            expression: "_value"
          }
        ],
        staticClass:
          "form-input rounded-0 border-left-0 border-right-0 text-center z-1",
        class: _vm.style,
        attrs: { type: "text", placeholder: _vm.placeholder },
        domProps: { value: _vm._value },
        on: {
          change: _vm.check,
          click: function($event) {
            return _vm.$emit("click", $event)
          },
          keyup: function($event) {
            return _vm.$emit("keyup", $event)
          },
          keydown: function($event) {
            return _vm.$emit("keydown", $event)
          },
          keypress: function($event) {
            return _vm.$emit("keypress", $event)
          },
          blur: function($event) {
            return _vm.$emit("blur", $event)
          },
          focus: function($event) {
            return _vm.$emit("focus", $event)
          },
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm._value = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "border-btn bg-lighter p-0 border-left-0",
          class: _vm.btnStyle,
          on: { click: _vm.add }
        },
        [_c("i", { staticClass: "i-plus" })]
      )
    ]),
    _vm._v(" "),
    _vm.help !== undefined
      ? _c("span", { staticClass: "form-help" }, [_vm._v(_vm._s(_vm.help))])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }